export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const STOP_ORDER_LOADING = "STOP_ORDER_LOADING";
export const SET_ERROR = "SET_ERROR";

export const START_HISTORY_LOADING = "START_HISTORY_LOADING";
export const STOP_HISTORY_LOADING = "STOP_HISTORY_LOADING";
export const START_HISTORY_SECONDARY_LOADING =
  "START_HISTORY_SECONDARY_LOADING";
export const STOP_HISTORY_SECONDARY_LOADING = "STOP_HISTORY_SECONDARY_LOADING";

export const START_BRAND_LOADING = "START_BRAND_LOADING";
export const STOP_BRAND_LOADING = "STOP_BRAND_LOADING";
export const START_BRAND_SECONDARY_LOADING = "START_BRAND_SECONDARY_LOADING";
export const STOP_BRAND_SECONDARY_LOADING = "STOP_BRAND_SECONDARY_LOADING";

export const START_RESEND_LOADING = "START_RESEND_LOADING";
export const STOP_RESEND_LOADING = "STOP_RESEND_LOADING";

export const START_SECONDARY_LOADING = "START_SECONDARY_LOADING";
export const START_FB_LOADING = "START_FB_LOADING";
export const STOP_FB_LOADING = "STOP_FB_LOADING";
export const STOP_SECONDARY_LOADING = "STOP_SECONDARY_LOADING";

export const START_TERTIARY_LOADING = "START_TERTIARY_LOADING";
export const STOP_TERTIARY_LOADING = "STOP_TERTIARY_LOADING";

export const START_CASHONDELI_LOADING = "START_CASHONDELI_LOADING";
export const STOP_CASHONDELI_LOADING = "STOP_CASHONDELI_LOADING";

export const START_CODLOADING_REWARD = "START_CODLOADING_REWARD";
export const STOP_CODLOADING_REWARD = "STOP_CODLOADING_REWARD";

export const ERROR_MESSAGE = "ERROR_MESSAGE";

export const STATUS_CODE = "STATUS_CODE";

export const SHOW_SUCCESS_BOX = "SHOW_SUCCESS_BOX";

export const IS_NO_MORE_DATA = "IS_NO_MORE_DATA";

export const IS_SUCCESS = "IS_SUCCESS";
export const IS_ERROR = "IS_ERROR";
export const IS_DOB_SUCCESS = "IS_DOB_SUCCESS";
export const CLEAR_DOB_SUCCESS = "CLEAR_DOB_SUCCESS";
export const CLEAR_SPECIALDAY_SUCCESS = "CLEAR_SPECIALDAY_SUCCESS";
export const IS_SPECIALDAY_SUCCESS = "IS_SPECIALDAY_SUCCESS";
export const SPECIAL_DAYS = "SPECIAL_DAYS";

/**
 * Home
 */
export const MAIN_CATEGORY = "MAIN_CATEGORY";
export const MAIN_CATEGORY_BY_ID = "MAIN_CATEGORY_BY_ID";
export const GOLD_STATE = "GOLD_STATE";
export const PROMOTION_PRODUCT = "PROMOTION_PRODUCT";
export const LATEST_PRODUCT = "LATEST_PRODUCT";
export const RECOMMEND_PRODUCT = "RECOMMEND_PRODUCT";
export const BEST_SELLING_PRODICT = "BEST_SELLING_PRODICT";
export const PRODUCT_BY_CATEGORY = "PRODUCT_BY_CATEGORY";
export const PRODUCT_BY_RANDOM = "PRODUCT_BY_RANDOM";
export const BANNER_LIST = "BANNER_LIST";
export const AD_LIST = "AD_LIST";
export const BUY_ONE_GET_ONE_LIST = "BUY_ONE_GET_ONE_LIST";
export const POP_UP_BANNER = "POP_UP_BANNER";
export const GET_BRAND = "GET_BRAND";
export const MAIN_CATEGORIES_DETAIL = "MAIN_CATEGORIES_DETAIL";
export const GET_REWARD_PRODUCTS = "GET_REWARD_PRODUCTS";
export const GET_PRODUCT_DETAIL_HOME = "GET_PRODUCT_DETAIL_HOME";

/**
 * Authentication
 */
export const CITY_LIST = "CITY_LIST";
export const TOWNSHIP_LIST = "TOWNSHIP_LIST";
export const CLEAR_CITY_LIST = "CLEAR_CITY_LIST";
export const CLEAR_TOWNSHIP_LIST = "CLEAR_TOWN_LIST";

/**
 * MyAccount
 */
export const START_SURVEY_PAGE_LOADING = "START_SURVEY_PAGE_LOADING";
export const STOP_SURVEY_PAGE_LOADING = "STOP_SURVEY_PAGE_LOADING";
export const QUESTION_LISTS = "QUESTION_LISTS";

/**
 * MyAccount
 */
export const USER_ACCOUNT_INFO = "USER_ACCOUNT_INFO";
export const GET_WISH_LIST_PRODUCT = "GET_WISH_LIST_PRODUCT";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const GET_TERMS_AND_CONDITIONS = "GET_TERMS_AND_CONDITIONS";
export const GET_MEMBERPOINTS = "GET_MEMBERPOINTS";
export const TERM_START_LOADING = "TERM_START_LOADING";
export const TERM_STOP_LOADING = "TERM_STOP_LOADING";

/**
 * ProductDetails
 */
export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const CLEAR_PRODUCT_DETAILS = "CLEAR_PRODUCT_DETAILS";
export const RELATED_PRODUCT_BY_CATEGORY = "RELATED_PRODUCT_BY_CATEGORY";
export const GET_VARIANT_VALUE = "GET_VARIANT_VALUE";
export const GET_Q_AND_A_LIST = "GET_Q_AND_A_LIST";
export const GET_Q_AND_A_LIST_BY_USER = "GET_Q_AND_A_LIST_BY_USER";
export const GET_QUESTION_COUNT = "GET_QUESTION_COUNT";
export const SAVE_QA_ID = "SAVE_QA_ID";
/**
 * Shopping Cart
 */
export const SHOPPING_CART_DETAIL = "SHOPPING_CART_DETAIL";

export const AVAILABLE_PRODUCT_LIST = "AVAILABLE_PRODUCT_LIST";
export const OUT_OF_STOCK_PRODUCT_LIST = "OUT_OF_STOCK_PRODUCT_LIST";
export const STOCK_LEFT_PRODUCT_LIST = "STOCK_LEFT_PRODUCT_LIST";
export const TOTAL_AMOUNT = "TOTAL_AMOUNT";

export const ORDER_ID = "ORDER_ID";
export const BANK_LIST = "BANK_LIST";
export const PAYMENT_SERVICE_DETAIL = "PAYMENT_SERVICE_DETAIL";
export const SET_ORDERDETAIL_FOR_APPROVAL = "SET_ORDERDETAIL_FOR_APPROVAL";
export const SET_NORMAL_ORDER = "SET_NORMAL_ORDER";

/**
 * Product Search
 */
export const GET_TAG_LIST = "GET_TAG_LIST";
export const GET_PRODUCT_SEARCH_LIST = "GET_PRODUCT_SEARCH_LIST";
export const GET_PRODUCT_SEARCH_COUNT = "GET_PRODUCT_SEARCH_COUNT";
export const GET_PRODUCT_SUGGESTION_LIST = "GET_PRODUCT_SUGGESTION_LIST";
export const CLEAR_DATA_LIST = "CLEAR_DATA_LIST";
export const BOUGHT_TOGETHER_PRODUCT_LIST = "BOUGHT_TOGETHER_PRODUCT_LIST";
export const NO_RESULT_FOUND = "NO_RESULT_FOUND";

/**
 * Order History
 */
export const GET_ORDER_HISTORY_LIST = "GET_ORDER_HISTORY_LIST";
export const GET_VOUCHER_NO_SUGGESTION = "GET_VOUCHER_NO_SUGGESTION";

/**
 * Order Detail
 */
export const GET_CART_DETAIL = "GET_CART_DETAIL";
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";
export const GET_BANK_LIST = "GET_BANK_LIST";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const GET_POS_VOUCHER = "GET_POS_VOUCHER";
export const GET_ORDER_MESSAGE = "GET_ORDER_MESSAGE";

/**
 * Payment
 */
export const POST_KBZ_PAYMENT = "POST_KBZ_PAYMENT";
export const POST_WAVE_PAYMENT = "POST_WAVE_PAYMENT";
export const GET_TRANSACTION_ID = "GET_TRANSACTION_ID";
export const GET_PAYMENT_SERVICE_BY_ID = "GET_PAYMENT_SERVICE_BY_ID";

/**
 * Product List
 */
export const SET_DATA_EXIST = "SET_DATA_EXIST";

/**
 * Notification
 */
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const SET_NO_MORE_DATA = "SET_NO_MORE_DATA";
export const SET_PAGE_DEFAULT = "SET_PAGE_DEFAULT";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const CHANGE_NOTI_COUNT = "CHANGE_NOTI_COUNT";
export const SHOW_HIDE_NOTIFICATION = "SHOW_HIDE_NOTIFICATION";
export const NOTI_START_LOADING = "NOTI_START_LOADING";
export const NOTI_STOP_LOADING = "NOTI_STOP_LOADING";

// counts
export const SET_ALL_COUNT = "SET_ALL_COUNT";

/**
 * Shipping Warranty Policy Terms & Conditions
 */
export const GET_SHIPPING = "GET_SHIPPING";
export const GET_WARRANTY = "GET_WARRANTY";
export const GET_POLICY = "GET_POLICY";
export const GET_TERMS = "GET_TERMS";
export const GET_INSTALLATION = "GET_INSTALLATION";
export const GET_PREORDER = "GET_PREORDER";

/**
 * Shipping Warranty Policy PreOrderTC With ID
 */
export const GET_SHIPPING_ID = "GET_SHIPPING_ID";
export const GET_WARRANTY_ID = "GET_WARRANTY_ID";
export const GET_POLICY_ID = "GET_POLICY_ID";
export const GET_INSTALLATION_ID = "GET_INSTALLATION_ID";
export const GET_PREORDER_ID = "GET_PREORDER_ID";

/**
 * Bycategory
 */
export const SET_SUB_CATEGORY_LIST = "SET_SUB_CATEGORY_LIST";
export const SET_MAIN_CATEGORYDETAIL = "SET_MAIN_CATEGORYDETAIL";
export const SET_PRODUCT_BY_BRAND = "SET_PRODUCT_BY_BRAND";
export const SET_MAIN_CATEGORY = "SET_MAIN_CATEGORY";
export const SET_SUB_CATEGORY = "SET_SUB_CATEGORY";

/**
 * Brand
 */
export const PRODUCT_BY_BRAND = "PRODUCT_BY_BRAND";
export const PRODUCT_COUNT_BRAND = "PRODUCT_COUNT_BRAND";

/**
 * Delivery Address
 */
export const GET_DELIVERY_ADDRESS = "GET_DELIVERY_ADDRESS";
export const CHANGED_SELECTED_VAL = "CHANGED_SELECTED_VAL";
export const DELETE_RESPONSE = "DELETE_RESPONSE";

/**
 * Recommend
 */
export const GET_RECOMMEND_HEADER = "GET_RECOMMEND_HEADER";
export const START_RECOMMEND_HEADER_LOADING = "START_RECOMMEND_HEADER_LOADING";
export const STOP_RECOMMEND_HEADER_LOADING = "STOP_RECOMMEND_HEADER_LOADING";

export const GET_RELATED_RECOMMEND_PRODUCTS = "GET_RELATED_RECOMMEND_PRODUCTS";
export const START_RELATED_RECOMMEND_PRODUCTS =
  "START_RELATED_RECOMMEND_PRODUCTS";
export const STOP_RELATED_RECOMMEND_PRODUCTS =
  "STOP_RELATED_RECOMMEND_PRODUCTS";
export const SET_TOTAL_PAGES_RECOMMEND = "SET_TOTAL_PAGES_RECOMMEND";
export const CLEAR_RELATED_RECOMMENDED_PRODUCTS =
  "CLEAR_RELATED_RECOMMENDED_PRODUCTS";
export const CLEAR_RECOMMENDED_HEADER = "CLEAR_RECOMMENDED_HEADER";

/**
 * Rewards
 */
export const START_GET_ALL_REWARD_PRODUCTS = "START_GET_ALL_REWARD_PRODUCTS";
export const STOP_GET_ALL_REWARD_PRODUCTS = "STOP_GET_ALL_REWARD_PRODUCTS";
export const SET_ALL_REWARD_PRODUCTS = "GET_ALL_REWARD_PRODUCTS";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const CLEAR_REWARD_PRODUCTS = "CLEAR_REWARD_PRODUCTS";

/**
 * Other Variant Values
 */
export const GET_OTHER_VARIANT_VLAUES = "GET_OTHER_VARIANT_VLAUES";
export const CLEAR_VARIATIONS = "CLEAR_VARIATIONS";
export const GET_PRODUCT_DETAIL_FOR_VARIATION =
  "GET_PRODUCT_DETAIL_FOR_VARIATION";
export const POP_UP_VARIANTS = "POP_UP_VARIANTS";
export const HOME_ADD_TO_CART_START_LOADING = "HOME_ADD_TO_CART_START_LOADING";
export const HOME_ADD_TO_CART_STOP_LOADING = "HOME_ADD_TO_CART_STOP_LOADING";

// Point Detail
export const GET_POINT_DETAIL = "GET_POINT_DETAIL";

// View all member levels
export const VIEW_ALL_MEMBER_LEVELS = "VIEW_ALL_MEMBER_LEVELS";
export const SET_MEMBER_POINT = "SET_MEMBER_POINT";
export const START_MEMBER_LEVEL_LOADING = "START_MEMBER_LEVEL_LOADING";
export const STOP_MEMBER_LEVEL_LOADING = "STOP_MEMBER_LEVEL_LOADING";

// history
export const HISTORY = "HISTORY";
export const CLEAN_HISTORY = "CLEAN_HISTORY";

// paybackpoint products
export const SET_PAYBACK_PRODUCTS = "SET_PAYBACK_PRODUCTS";

// redeem with point
export const REDEEM_WITH_POINT = "REDEEM_WITH_POINT";
export const SET_REDEEM_COIN = "SET_REDEEM_COIN";

// check allow to exchange with memberpoint or not
export const ALLOW_EXCHANGE_WITH_POINT = "ALLOW_EXCHANGE_WITH_POINT";

// set weight
export const SET_WEIGHT = "SET_WEIGHT";

// shop by brands
export const POPULAR_BRANDS_START_LOADING = "POPULAR_BRANDS_START_LOADING";
export const POPULAR_BRANDS_STOP_LOADING = "POPULAR_BRANDS_STOP_LOADING";
export const ALPHABET_SEARCH_START_LOADING = "ALPHABET_SEARCH_START_LOADING";
export const ALPHABET_SEARCH_STOP_LOADING = "ALPHABET_SEARCH_STOP_LOADING";
export const TOTAL_RESULT_COUNT = "TOTAL_RESULT_COUNT";

export const SELECTED_KEYWORD = "SELECTED_KEYWORD";

export const GET_POPULAR_BRANDS = "GET_POPULAR_BRANDS";
export const GET_BRANDS_BY_ALPHABET = "GET_BRANDS_BY_ALPHABET";

export const GET_BRANDS_BY_NAME = "GET_BRANDS_BY_NAME";
export const TOTAL_NAME_RESULT_COUNT = "TOTAL_NAME_RESULT_COUNT";

export const CURRENT_PAGE = "CURRENT_PAGE";
export const TOTAL_PAGE = "TOTAL_PAGE";

export const BACK_TO_ZERO_LIST = "BACK_TO_ZERO_LIST";

export const CLEAR_CART = "CLEAR_CART";

// member card
export const GET_MEMBER_CARD = "GET_MEMBER_CARD";

// delete account
export const GET_REASON_FOR_DELETE_USER = "GET_REASON_FOR_DELETE_USER";

export const SET_IFRAME_URL = "SET_IFRAME_URL";

export const SET_TRANSCATION_ID = "SET_TRANSCATION_ID";

// app setting
export const SET_APP_SETTING = "SET_APP_SETTING";

// cart count
export const SHOPPINGCART_COUNT = "SHOPPINGCART_COUNT";

// search by brands
export const SET_SEARCH_BY_BRANDS = "SET_SEARCH_BY_BRANDS";
export const REMOVE_SEARCH_BRANDS = "REMOVE_SEARCH_BRANDS";

// reward loading
export const START_REWARD_ITEM_LOADING = "START_REWARD_ITEM_LOADING";
export const STOP_REWARD_ITEM_LOADING = "STOP_REWARD_ITEM_LOADING";
